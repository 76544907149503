.tool-management-table {
    border-radius: 5px;

    thead {
        position: relative;
        z-index: 1;
    }

    tr {
        &:hover {
            cursor: default;
        }
    }

    td {
        &:hover {
            cursor: default;
        }
    }

    th, td {
        &[name="status"] {
            width: 150px;
            text-align: center;
        }
        
        &[name="material"] {
            width: 350px;
        }
    }

    th {
        top: 112px;

        &:first-child {
            border-radius: 5px 0 0 0;
        }

        &:last-child {
            border-radius: 0 5px 0 0;
        }
    }

    .tool-operation {
        display: table;

        div {
            display: table-row;
        }

        dt {
            display: table-cell;
            padding-right: 8px;
        }

        dd {
            display: table-cell;
        }
    }

    .material-match-indicator {
        display: inline-block;
        padding: 8px;
        background-color: #e4e7eb;
        border-radius: 100%;

        &::before {
            content: "";
            display: block;
            width: 16px;
            height: 16px;
            border-radius: 100%;
            background-color: #ff7777;
            border: 2px solid #fff;
        }

        &.is-match::before {
            background-color: #65d6ad;
        }
    }
}
