$border-color: #e6e6e6;

.link-orders-modal {
    .modal-toolbar {
        border-bottom-width: 0;
    }

    .modal-body {
        padding: 0;
    }

    table {
        $border: 1px solid $border-color;

        border-top: $border;

        tr {
            &:last-child {
                td {
                    border-bottom-width: 0;
                }
            }
        }

        th,
        td {
            &:first-child {
                padding-left: 16px;
            }

            &:last-child {
                padding-right: 16px;
            }

            &.column-link {
                width: 48px;
                text-align: left;
            }
        }

        .column-link {
            text-align: center;
        }

        .false-link {
            background-color: rgb(254, 243, 242);
        }

        .disabled-link {
            &:hover {
                background-color: transparent;
                cursor: default;
            }

            td {
                color: #9aa5b1;
            }
        }

        .first-order-row {
            border-top: 2px solid $border-color;
        }
    }
}

.link-orders-modal--order {
    max-width: 576px;
}

.link-orders-modal--parent-order {
    max-width: 768px;
}