.stock-requirements-filters {
    display: flex;
    align-items: center;

    .material-filter {
        width: 100%;
    }
}

.planning-table-app-menu {
    min-width: 1238px;

    .nav-link.dropdown-toggle {
        &::before {
            background-image: unset !important;
        }
        &::after {
            background-image: unset !important;
        }
    }
}

.ql-type-filter {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    align-items: center;

    .type-dropdown {
        width: 40%;
    }
    .value-dropdown {
        width: 60%;
        margin-left: 5px;
    }
}

.left-menu-orders-divider {
    $dot-size: 3px;
    $border-color: #ccc;
    $dot-color: #ccc;
    $dot-color-dark: #aaa;

    position: relative;
    width: 100%;
    height: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F2F2F2;
    border: 1px solid $dot-color;
    border-right-width: 0;
    border-left-width: 0;
    cursor: row-resize;

    i {
        position: relative;
        display: block;
        width: $dot-size;
        height: $dot-size;
        background-color: $dot-color;
        border-radius: 50%;

        &::before,
        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: -2 * $dot-size;
            width: $dot-size;
            height: $dot-size;
            border-radius: 50%;
            background-color: $dot-color;
        }

        &::after {
            left: 2 * $dot-size;
        }
    }

    &:hover {
        i {
            background-color: $dot-color-dark;

            &::before,
            &::after {
                background-color: $dot-color-dark;
            }
        }
    }
}

#left-content {
    #order-row {
        user-select: auto;
        pointer-events: auto;
    }

    #order-row-detailed {
        user-select: auto;
        pointer-events: auto;
    }
}


.disable-user-events#scheduled-orders, .disable-user-events#unscheduled-orders {
    user-select: none !important;
    pointer-events: none !important;

    #order-row {
        user-select: none !important;
        pointer-events: none !important;
    }

    #order-row-detailed {
        user-select: none !important;
        pointer-events: none !important;
    }
}

#alerts-modal .modal-body {
    padding: unset;
}

.ql-type-filter {
    [class*="-container"] {
        z-index: 999 !important
    }
}

.abort-button {
    width: 100%;
    font-weight: 300;
    align-items: center;
    i {
        color: #B9E3FF;
    }
}