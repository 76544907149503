@import "../../../styles/globals.scss";

$font-size: 9px;

.chevron-list-item {
    display: flex;
    height: auto;
    align-items: stretch;
    cursor: pointer !important;
    flex: 1 0 auto;
    min-height: 50px;

    &.active {
        background-color: $theme-blue;
        .chevron {
            color: white;
            background-color: $theme-blue;
            opacity: 1;
        }
    }

    .chevron {
        display: flex;
        align-items: center;
        width: 20px;
        color: #212932;
        background-color: #e4e7eb;
        justify-content: center;
        font-size: $font-size;
        border-top: white;
        opacity: 0;
        margin-right: 20px;
        transition: opacity 0.5s;
    }

    &:hover:not(.active) {
        background-color: #e4e7eb;
        .chevron {
            opacity: 1;
            background-color: #9AA5B1;
        }
    }

    &.active {
        background-color: white;
    }

    .text {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 5px;
        padding-left: unset;
    }
}

.dropdown-chevron-list-item {
    display: flex;
    height: auto;
    align-items: stretch;
    cursor: pointer !important;
    flex: 1 0 auto;
    min-height: 50px;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;

    &.active {
        background-color: $theme-prod-color-light;
        &[data-sys-flag="dev"] {
            background-color: $theme-dev-color-light;
        }
        &[data-sys-flag="beta"] {
            background-color: $theme-beta-color-light;
        }
        &[data-sys-flag="alpha"] {
            background-color: $theme-alpha-color-light;
        }

        .chevron {
            color: white;
            background-color: $theme-blue;
            opacity: 1;
        }
    }

    .chevron {
        display: flex;
        align-items: center;
        width: 8px;
        background-color: $theme-icon-color;
        justify-content: center;
        font-size: $font-size;
        opacity: 0;
        margin-right: 20px;
    }

    &:hover:not(.active) {
        background-color: $theme-prod-color-light;
        &[data-sys-flag="dev"] {
            background-color: $theme-dev-color-light;
        }
        &[data-sys-flag="beta"] {
            background-color: $theme-beta-color-light;
        }
        &[data-sys-flag="alpha"] {
            background-color: $theme-alpha-color-light;
        }
    }

    .text {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 5px;
        padding-left: 20px;
    }
}