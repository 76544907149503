@import "../../styles/globals.scss";

.left-menu-icon-option {
    padding: 20px;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;

    &.active {
        background-color: $theme-blue;
        border: 1px solid rgba(255, 255, 255, 0.1);
        color: white;

        .icon {
            color: white !important;
        }
    }

    &:hover:not(.active) {
        background-color: $theme-prod-color-light;

        &[data-sys-flag="dev"] {
            background-color: $theme-dev-color-light;
        }
        &[data-sys-flag="beta"] {
            background-color: $theme-beta-color-light;
        }
        &[data-sys-flag="alpha"] {
            background-color: $theme-alpha-color-light;
        }

        .chevron {
            opacity: 1;
        }
    }

    &:hover {
        color: white;
    }

    color: white;
    font-weight: 400;
    display: flex;
    align-items: center;

    .icon {
        font-size: 20px;
        color: white;
    }
    .label {
        margin-left: 10px;
        user-select: none;
    }
}

.left-menu-dropdown {
    color: white;
    cursor: pointer;

    &.open.active {
        .left-menu-icon-option {
            background-color: $theme-blue;
            border: 1px solid rgba(255, 255, 255, 0.1);
        }
    }

    .options {
        background-color: $theme-prod-color;

        .env-dev & {
            background-color: $theme-dev-color;
        }
        .env-alpha & {
            background-color: $theme-alpha-color;
        }
        .env-beta & {
            background-color: $theme-beta-color;
        }
    }

    .left-menu-icon-option {
        display: flex;
        padding: 20px;
        align-items: center;

        .icon {
            font-size: 20px;
        }
        .label {
            margin-top: 5px;
            margin-left: 8px;
            i {
                margin-left: 5px;
            }
        }
    }
}