.operation-links-modal {
    max-width: 992px;

    .filter-select {
        width: 100%;
    }

    .alert-no-data {
        margin-top: 0;
        margin-bottom: 0;
    }

    .table {
        margin-bottom: 0;

        th,
        td {
            &:first-child {
                padding-left: 16px;
            }

            &:last-child {
                padding-right: 16px;
            }

            &[name="process_num"] {
                width: 180px;
            }

            &[name="actions"] {
                width: 64px;
                text-align: right;
            }
        }
    }

    .react-table-pagination {
        position: sticky;
        bottom: 0;
        width: 100%;
        padding-top: 16px;
        padding-bottom: 16px;
        margin-top: -1px;
        background-color: #fff;
        border-top: 1px solid #e9ecef;
    }
}