@import "./globals.scss";

$navbar-header-height: 60px;
$left-menu-width: 250px;
$max-height: 100vh;

@mixin lighten-background($color) {
    background-color: rgba($color, 0.99);
}

// overrides
.grid-layout-overrides {
    .views-content {
        padding-top: unset !important;
    }
}

// grid
.grid-layout {
    display: grid;
    grid-template-areas:
        "grid-header grid-header"
        "grid-left-menu grid-main-content";

    grid-template-columns: $left-menu-width auto;

    .grid-header {
        grid-area: grid-header;
        position: sticky;
        top: 0px;
        z-index: 1000;
    }

    .grid-left-menu {
        grid-area: grid-left-menu;
        background-color: $theme-prod-color-alt;
        width: $left-menu-width;
        height: calc(100vh - $navbar-header-height);
        overflow-y: auto;
        &.env-dev {
            background-color: $theme-dev-color-alt;
        }
        &.env-alpha {
            background-color: $theme-alpha-color-alt;
        }
        &.env-beta {
            background-color: $theme-beta-color-alt;
        }
    }

    .grid-main-content {
        grid-area: grid-main-content;
        height: calc(100vh - $navbar-header-height);
        max-width: calc(100vw - $left-menu-width);
        overflow-y: scroll;

        .img-fluid-header {
            height: 50px;
        }
        // overrides
        .views-filter-bar {
            width: calc(100% - 250px);
        }
        .electricity-table th {
            top: 50px !important;
        }
    }

    .resources-layout {
        display: flex;
        flex-direction: column;

        .header {
            background-color: white;
            display: flex;
            justify-content: space-between;
            background-color: white;
            padding: 20px;
        }

        .resources-layout-main {
            display: flex;
            max-height: $max-height;

            .resources-layout-list {
                width: 33%;
                height: calc(100vh - $navbar-header-height);
                max-height: $max-height;
                overflow-y: scroll;
                min-width: 200px;

                .search {
                    background-color: #edf0f5;
                    padding: 5px;
                    align-items: center;
                    display: flex;
                    justify-content: space-between;
                    //overrides
                    .form-group {
                        margin-bottom: unset;
                        margin-left: 5px;
                    }
                    input {
                        width: 100%;
                    }
                    .sub-filter-bar {
                        padding-left: 10px !important;
                        padding-right: 10px !important;
                        width: 100%;
                    }
                }
            }
            .resources-layout-content {
                width: 100%;
                height: calc(100vh - $navbar-header-height);
                max-height: $max-height;
                overflow-y: auto;
                padding-left: 10px;
                .alert-no-data {
                    margin: 10px !important;
                }
            }
        }

        .nav-item {
            cursor: pointer;
        }
    }
}
