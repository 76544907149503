@use "sass:math";

$comment-form-gutter: 8px;
$comment-form-spacing: math.div($comment-form-gutter, 2);

// .order-table-production {}

.order-table-production__comment-form {
    .form-row {
        flex-wrap: nowrap;
        align-items: center;
        margin: 0 (-$comment-form-spacing) $comment-form-gutter;

        &:last-child {
            margin-bottom: 0;
        }

        .alert {
            width: 100%;
            margin: 0 ($comment-form-spacing);
        }

        .form-control,
        .btn {
            margin-right: $comment-form-spacing;
            margin-left: $comment-form-spacing;
        }

        .fa-spinner {
            padding-right: $comment-form-spacing;
            padding-left: $comment-form-spacing;
            margin-left: auto;
            visibility: hidden;
        }
    }
}

.order-table-production__comment {
    position: relative;
    padding: 4px;
    margin-bottom: 0;
    border-radius: 5px;
    white-space: pre;

    button {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: text !important;

        &:hover,
        &:focus {
            background-color: #e4e7eb !important;
        }

        &:disabled {
            cursor: pointer !important;
        }
    }

    >span {
        position: relative;
        pointer-events: none;
    }
}