@use "sass:math";

$bg-color: #f5f7fa;
$border-color: #e4e7eb;
$tree-link-color: #b7bdc4;
$tree-link-color-dark: #404040;
$tree-link-border: 1px solid $tree-link-color;
$tree-link-size: 30px;
$tree-cell-padding-x: 15px;
$tree-cell-padding-y: 5px;
$tree-cell-padding: $tree-cell-padding-y $tree-cell-padding-x;
$tree-column-width: 150px;
$tree-node-spacing: 100px;
$tree-border: 1px solid $border-color;

.tree-view {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: math.div($tree-link-size, 2);
}

.tree-view-content {
    flex-grow: 1;
}

.tree-view-header {
    position: sticky;
    top: 0;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    padding: 0;
    margin: 0;
    font-weight: 500;
    background-color: #fff;
    border-bottom: 1px solid $border-color;

    li {
        display: flex;
        align-items: center;
        padding: $tree-cell-padding;
        margin: 0;
        list-style-type: none;

        &:first-child {
            flex-grow: 1;
        }

        &:nth-child(2) {
            position: relative;

            &::before {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                left: -1px;
                border-left: 1px dashed $border-color;
            }
        }

        &:nth-child(n + 1) {
            width: $tree-column-width;
        }
    }
}

.tree-view-nodes {
    padding-left: 2 * $tree-link-size;
    margin-bottom: 0;

    li {
        width: 100%;
        margin: 0;
        list-style-type: none;
        position: relative;
        padding: math.div($tree-link-size, 2) 0 0;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: auto;
            bottom: 2 * $tree-link-size;
            left: -$tree-link-size;
            width: 1px;
            height: 100%;
            border-left: $tree-link-border;
        }

        &::after {
            content: "";
            position: absolute;
            top: $tree-link-size;
            right: auto;
            left: -$tree-link-size;
            width: $tree-link-size;
            height: $tree-link-size;
            border-top: $tree-link-border;
        }

        &:last-child::before {
            height: $tree-link-size;
        }

        & dl {
            display: flex;
            align-items: stretch;
            justify-content: space-between;
            margin: 0;

            & > dt {
                display: flex;
                align-items: center;
                padding: $tree-cell-padding;
                margin-right: $tree-link-size;
                border: $tree-link-border;
                border-radius: 5px;
                font-weight: 500;
                text-decoration: none;
            }

            & > dd {
                display: flex;
                align-items: center;
                flex-shrink: 0;
                width: $tree-column-width;
                padding: $tree-cell-padding;
                margin: 0;
                border-top: 1px solid transparent;
                border-bottom: 1px solid transparent;

                &:first-of-type {
                    margin-left: auto;
                }
            }

            &:hover > dt {
                background-color: $bg-color;
                border-color: $tree-link-color-dark;
            }

            &:hover + ul li dl > dt {
                border-color: $tree-link-color-dark;
            }

            &:hover > dd {
                background-color: $bg-color;
                border-top-color: $border-color;
                border-bottom-color: $border-color;
            }

            &:hover + ul li dl > dd {
                border-top-color: $border-color;
                border-bottom-color: $border-color;
            }

            &:hover + ul li::after,
            &:hover + ul li::before,
            &:hover + ul::before,
            &:hover + ul ul::before {
                border-color: $tree-link-color-dark;
            }
        }
    }

    &[data-depth="0"] {
        $bullet-size: 7px;
        $bullet-offset: floor(
            $number: math.div($bullet-size, 2)
        );

        & > li {
            &:first-child {
                & > dl > dd:first-of-type::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    bottom: -(math.div($tree-link-size, 2));
                    left: unset;
                    width: 0;
                    margin-left: -($tree-cell-padding-x + 1);
                    border-left: 1px dashed $border-color;
                }
            }

            &::before {
                top: $tree-link-size - $bullet-offset;
                left: -($tree-link-size + $bullet-offset);
                width: $bullet-size;
                height: $bullet-size !important;
                background-color: $tree-link-color;
                border: 0;
                border-radius: 50%;
            }
        }
    }
}
