 $min-input-width: 280px;
 $input-width: 500px;

 .ticket-status {
     display: flex;
     align-items: center;
     padding-right: 5px;
     white-space: nowrap;

     .title {
         font-size: 1em;
         border-radius: 20em;
         padding: 5px;
         padding-left: 10px;
         padding-right: 10px;

         span {
             color: white;
             font-weight: 500;
         }
     }

     .circle {
         width: 20px;
         height: 20px;
         border-radius: 50%;
         margin-right: 5px;
     }
 }

 .ticketing {

     .ticket-header {
         font-size: 16px;
         display: flex;
         justify-content: space-between;
         cursor: pointer;
         align-items: center;
     }

     .navigation-header {
         margin-bottom: 10px;
     }

     .worker-inbox {
         display: flex;
         justify-content: center;
         flex-direction: column;
         padding: 20px;
         cursor: pointer;
         width: 100%;
     }

     .worker-inbox-item {
         min-width: $min-input-width;
         width: $input-width;
         background-color: white;
         border-radius: 1em;
         box-shadow: 1px 1px 1px 1px #cecece;
         margin: 3px;
         width: 100%;
         display: flex;
         flex-direction: row;
         padding: 10px;
         margin-bottom: 10px;

         &:hover {
             .status {
                 opacity: 0.8;
             }
         }

         .description {
             display: flex;
             flex-direction: column;
             flex-grow: 1;

             .title {
                 font-size: 1.5em;
             }

             .assignee {
                 font-size: 1em;
                 color: rgb(173, 178, 185);
             }

             .workflow-title {
                 font-size: 1em;
                 color: rgb(173, 178, 185);
             }
         }
     }

     .ticket-step-container {
         min-width: $min-input-width;
         width: $input-width;
         display: flex;
         background-color: white;
         border-radius: 1em;
         flex-direction: column;
         padding: 10px;

         .steps {
             .info {
                 display: flex;
                 flex-direction: column;
             }

             .field {
                 margin-bottom: 15px;
                 min-width: 100px;

                 &[type="checkbox"] {
                     display: flex;
                     width: 100%;
                     align-items: center;

                     input {
                         margin-right: 5px;
                     }
                 }

                 .input {
                     display: flex;
                     align-items: center;

                     & [class*="container"] {
                         width: 100%;
                     }

                     width: 100%;

                     i {
                         margin-left: 5px;
                         cursor: pointer;
                         color: rgb(161, 179, 200);
                     }
                 }

                 label {
                     color: rgb(141, 151, 161);
                     margin-bottom: unset;
                 }

                 & [class*="placeholder"] {
                     color: rgb(161, 179, 200);
                 }

                 input {
                     color: rgb(161, 179, 200);

                     &::placeholder {
                         color: rgb(161, 179, 200);
                     }
                 }
             }
         }
     }

     .navigation {
         padding: 15px;

         .main-body {
             display: flex;
             justify-content: center;
         }
     }

     .table.react-table {
         background-color: white;

         &:hover {
             background-color: white;
         }
     }

     .actions {
         display: flex;
         justify-content: flex-start;

         i {
             font-size: 1.5em;
         }
     }

     .user-step-title-small {
         font-size: 14px;
         font-weight: 500;
         margin-bottom: 10px;
     }

     .user-step-title-big {
         font-size: 24px;
         font-weight: 500;
         margin-bottom: 10px;
     }

     .user-step-subtitle-small {
         font-size: 12px;
         margin-bottom: 10px;
     }

     .user-step-subtitle-big {
         font-size: 20px;
         margin-bottom: 10px;
     }

     .user-step-buttons {
         &-centered {
             display: flex;
             justify-content: center;
         }

         display: flex;
         justify-content: flex-end;
         padding-top: 10px;
     }

     .user-step-field-button {
         margin: 0.2rem;
         border: "none";
         width: auto;
         font-size: 1rem;
         padding-top: "10px";
         display: "flex";
         justify-content: "flex-end";

         &-kiosk {
             margin: 0.2rem;
             border: none;
             font-size: 2rem;
             width: auto;
             padding-top: "10px";
             display: "flex";
             flex-direction: "column";
             justify-content: "flex-end";
         }

     }
 }

 .ticket-details {
     display: flex;
     flex-direction: column;

     .label {
         color: #708095;
     }

     .value {
         color: #203256;
     }
 }

 .ticket-details-table {
     max-height: max(350px, calc(100vh - 340px));
     overflow-y: scroll;
 }

 .ticketing-fa-icon {
     font-size: 1.3rem;
 }

 .ticketing-fa-icon-error {
     color: orangered;
 }

 .ticketing-fa-icon-warn {
     color: orange;
 }

 .ticketing-fa-icon-success {
     color: lightseagreen;
 }

 .action-button-bar {
     padding: 14px;
 }

 .router-buttons {
     .selected {
         background-color: #0062cc;
     }
 }