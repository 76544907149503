$theme-prod-color: #001d31;
$theme-dev-color: #048271;
$theme-alpha-color: #820482;
$theme-beta-color: #2251cc;
$theme-icon-color: #2085EB;
$theme-icon-color-dev: #1D2632;

$theme-prod-color-alt: #1c2836;
$theme-dev-color-alt: #0a7567;
$theme-alpha-color-alt: #6e096e;
$theme-beta-color-alt: #264aa5;

$theme-dev-color-light: #089481;
$theme-alpha-color-light: #9b00a1;
$theme-beta-color-light: #3a6ed5;
$theme-prod-color-light: #2d3e4f;

$theme-blue: #2186eb;