.user-logins {
    .alert {
        margin: 16px;
    }
}

.user-logins-table {
    tr {
        &.is-deleting {
            background-color: #ffe6e5;
        }
    }

    th,
    td {
        &:first-child {
            padding-left: 16px;
        }

        &:last-child {
            padding-right: 16px;
        }
    }
}

.user-logins-actions {
    width: 120px;
    text-align: right;

    & .btn {
        min-width: auto;
        width: 30px;
        margin-left: 8px;
    }
}
