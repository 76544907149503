
.editable-text-area-header {
    #comment-edit-button {
        &.btn {
            min-width: 40px;
        }        
    }
}

.editable-text-area {
    width: 100%;
    border-style: none; 
    border-color: Transparent; 
    overflow: auto;
    
    textarea {
        border-bottom: 1px solid #61a2ed;

        &:focus {
            border: 1px solid #61a2ed;
        }

        &:hover {
            border-color: none !important;
        }
    }
}