$bg-color: #f5f7fa;
$bg-light-color: #fff;
$border-color: #e4e7eb;
$toolbar-border-color: #dee2e6;
$selected-td-bg-color: rgba(247, 237, 208, 0.5);
$selected-th-bg-color: #fbf6e7;
$error-bg-color: rgba(247, 218, 218, 0.5);

.electricity-tabs {
    .nav-tabs {
        margin-bottom: 16px;
    }
}

.electricity-input-toolbar {
    margin-top: -16px;
    padding: 8px 0;
}

.electricity-table {
    tr.is-invalid {
        background-color: $error-bg-color;
    }

    th,
    td {
        &:first-child {
            padding-left: 15px;
        }

        &:last-child {
            padding-right: 15px;
        }
    }

    th {
        position: sticky;
        top: 112px;
        z-index: 1;
        font-size: 14px !important;
        font-weight: 500 !important;
        background-color: $bg-light-color;
    }
}

.electricity-table-input {
    thead {
        .column-consumption {
            &.is-selected {
                background-color: $selected-th-bg-color;
            }
        }
    }

    .column-name {
        width: 320px;
    }

    .column-consumption {
        width: 200px;

        &.is-selected {
            background-color: $selected-td-bg-color;
        }

        input {
            min-width: 120px;
        }
    }

    .column-cost {
        text-align: right;
    }

    .column-overhead {
        text-align: right;
    }

    .column-extras {
        text-align: right;
    }
}

.electricity-table-output {
    table th {
        position: static;
        background-color: transparent;
    }

    th {
        &:first-child {
            border-top-left-radius: 5px;
        }

        &:last-child {
            border-top-right-radius: 5px;
        }
    }

    [name="duration"] {
        text-align: right;
    }

    [name="stock"] {
        text-align: right;
    }

    [name="electricity_consumption"] {
        text-align: right;
    }

    [name="total_electricity_consumption"] {
        text-align: right;
    }

    [name="actions"] {
        width: 80px;
        text-align: right;
    }

    .alert-no-data {
        margin: 0;
    }
}

.electricity-table-realization {
    [name="shift_number"] {
        text-align: right;
    }

    [name="duration"] {
        text-align: right;
    }
}

.electricity-input-materials-tree {
    dt {
        & em {
            font-style: normal;
            color: #9aa5b1;

            &::before {
                content: "(";
            }

            &::after {
                content: ")";
            }
        }
    }

    .column-factor {
        justify-content: stretch;
    }

    .column-duration {
        justify-content: end;
        text-align: right;
    }

    .column-stock {
        justify-content: end;
        text-align: right;
    }

    .column-net-consumption {
        justify-content: end;
        text-align: right;
    }

    .column-gross-consumption {
        justify-content: end;
        text-align: right;
    }
}
