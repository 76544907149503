.user-logins-input-modal {
    form {
        .form-group {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .alert {
        margin-bottom: 0;
    }

    .modal-footer {
        & > i:first-child {
            font-size: 16px;
            margin-right: auto;
        }
    }
}
