$border-color: #e4e7eb;

.kpi-box {
    background-color: #fff;
    border: 1px solid $border-color;
    border-radius: 5px;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.12);
}

.kpi-box-header {
    padding-top: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid $border-color;
}

.kpi-box-label {
    font-size: 16px;
}

.kpi-box-item {
    margin-top: 8px;

    &:first-child {
        margin-top: 0;
    }
}

.kpi-box-comment {
    &::after {
        content: ":";
    }
}

.kpi-box-number {
    padding: 4px 12px;
    background-color: #ffe6e5;
    border-radius: 5px;

    strong {
        font-size: 28px;
    }

    em {
        font-size: 18px;
        font-style: normal;
    }

    svg {
        color: #de3a11;
    }
}

.kpi-box-sparkline {
    & > svg {
        width: 100%;
        height: 100%;
        max-height: 50px;
    }
}
