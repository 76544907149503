
$ql-tabs-header-height: 40px;
$filter-bar-height: 164px;
$active-tab-background-color: white;
$inactive-tab-background-color: #E4E7EB;

.ql-tabs {

    .ql-tabs-header {
        display: flex;
        height: $ql-tabs-header-height;
        align-items: center;

        .ql-tab:first-child {
            margin-left: 5px
        }

        .ql-tab {
            background-color: $inactive-tab-background-color;
            margin-right: 5px;
            height: $ql-tabs-header-height;
            min-width: 0;
            display: flex;
            align-items: center;
            padding-left: 8px;
            padding-right: 8px;
            cursor: pointer;

            &.active {
                background-color: $active-tab-background-color;
            }

            & > span {
                display: flex;
                align-items: center;
                min-width: 0;

                & > span {
                    min-width: 0;
                    margin-left: 5px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }

    .ql-tabs-content-relative {
        position: relative;
        left: 0px;
        top: 0px;
        z-index: 1;
        background-color: white;
        max-height: calc(100vh - ($filter-bar-height + $ql-tabs-header-height));
        overflow: auto;

        .content {
            opacity: 0;
            display: none;

            &.visible {
                opacity: 1;
                display: block;
            }
        }
    }
}
