.order-table-production-modal__tags {
    .badge {
        margin: 2px;
        font-size: 12px;
    }
}

.order-table-production-modal__productions {
    table {
        margin-bottom: 0;
    }

    tfoot {
        border-top: 2px solid #e6e6e6;
        
        th {
            font-weight: 500;
        }
    }
}
