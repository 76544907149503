#materials-search {
    width: 100%;
    position: sticky;
    top: 0px;
    display: flex;
    flex-direction: column;

    div {
        width: 100%;
    }
}