$border-color: #d5d5d5;
$insight-bg-color-1: rgba(71, 163, 243, 0.05);
$insight-bg-color-2: rgba(71, 163, 243, 0.3);
$insight-bg-color-alt-1: rgba(247, 135, 130, 0.05);
$insight-bg-color-alt-2: rgba(247, 135, 130, 0.3);
$insight-border-color-1: rgba(71, 163, 243, 0.3);
$insight-border-color-2: rgba(247, 135, 130, 0.3);
$line-bg-color-1: rgba(71, 163, 243, 0.8);
$line-bg-color-2: rgba(247, 135, 130, 0.8);
$between-insight-border-color: rgba(71, 163, 243, 0.3);

.tool-setup-insights {
    list-style: none;
    margin-bottom: 0;
    columns: 2;
    column-gap: 20px;
    column-rule: solid 1px $border-color;
}

.tool-setup-line {
    width: 100%;
    padding: 4px 8px;
    font-weight: 700;

    &.even {
        background-color: $line-bg-color-1;
    }

    &.odd {
        background-color: $line-bg-color-2;
    }

    border-bottom: 1px solid $border-color;
}

.tool-setup-insight {
    border-bottom: 1px solid $border-color;

    &.even-even {
        background-color: $insight-bg-color-1;
    }

    &.even-odd {
        background-color: $insight-bg-color-2;
    }

    &.odd-even {
        background-color: $insight-bg-color-alt-1;
    }

    &.odd-odd {
        background-color: $insight-bg-color-alt-2;
    }

    dl {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 0;
        break-inside: avoid-column;

        div {
            display: flex;
            margin-bottom: 0;
        }

        dt {
            padding: 0px 8px;
            font-weight: 500;
        }

        dd {
            padding: 0px 8px;
            margin-bottom: 0;
        }
    }
}

.tool-setup-insight-date {
    border-top: 3px solid $between-insight-border-color;
    width: 100%;

    dt,
    dd {
        flex-grow: 1;
        padding-right: 4px;
        padding-left: 4px;

    }
}

.even-even .tool-setup-insight-date,
.even-odd .tool-setup-insight-date {

    dd,
    dt {
        border-right: 1px dashed $insight-border-color-1;
        border-bottom: 1px dashed $insight-border-color-1;

        &:last-child {
            border-right: none;
        }
    }
}

.odd-even .tool-setup-insight-date,
.odd-odd .tool-setup-insight-date {

    dd,
    dt {
        border-right: 1px dashed $insight-border-color-2;
        border-bottom: 1px dashed $insight-border-color-2;

        &:last-child {
            border-right: none;
        }
    }
}

.tool-setup-insight-material {
    width: 50%;
    text-align: center;
}

.even-even .tool-setup-insight-material,
.even-odd .tool-setup-insight-material {
    border-bottom: 1px dashed $insight-border-color-1;

    dd,
    dt {
        border-right: 1px dashed $insight-border-color-1;
        border-bottom: 1px dashed $insight-border-color-2;

        &:last-child {
            border-right: none;
        }
    }
}

.odd-even .tool-setup-insight-material,
.odd-odd .tool-setup-insight-material {
    border-bottom: 1px dashed $insight-border-color-2;

    dd,
    dt {
        border-right: 1px dashed $insight-border-color-1;
        border-bottom: 1px dashed $insight-border-color-2;

        &:last-child {
            border-right: none;
        }
    }
}

.tool-setup-insight-material {
    width: 100%;

    dd {
        flex-grow: 1;
    }
}

.tool-setup-insight-comment {
    width: 100%;

    dt {
        padding-right: 0 !important;

        &::after {
            content: ":";
        }
    }

    dd {
        overflow: hidden;
        max-height: 21px;
    }
}

.even-even .tool-setup-insight-comment,
.even-odd .tool-setup-insight-comment {
    border-top: 1px dashed $insight-border-color-1;
}

.odd-even .tool-setup-insight-comment,
.odd-odd .tool-setup-insight-comment {
    border-top: 1px dashed $insight-border-color-2;
}
