$border-color: #e6e6e6;

.performance-expand-button {
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 50px;
    z-index: 1050;
}

.performance- {
    .modal-body {
        padding: unset;
    }
}

.performance-content {
    pointer-events: unset;
    display: grid;
    grid-template-areas: "left-menu main-view";
    grid-template-columns: 1fr 3fr;
    height: 80vh;

    .performance-left-menu {
        grid-area: left-menu;
        border-right: 1px solid $border-color;
        height: 100%;
        cursor: pointer;

        .left-menu-option {
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
            border-bottom: 1px solid $border-color;

            &:hover {
                background-color: #e6e6e6;
            }

            &[data-selected="true"] {
                background-color: #f3f2f2;
            }
        }
    }

    .performance-main-view {
        grid-area: main-view;
        text-align: center;
        width: 100%;
    }
}

.performance-highlight {
    border: 2px solid red;
}